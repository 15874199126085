import React from 'react';
import MillsLogo from '../assets/images/mills-logo.svg';

const Contact = (props) => (
  <section id="contact">
    <div className="inner" id="two">
      <section>
        {/* <form
          method="post"
          action="https://getform.io/f/aff1ac08-79f2-4fe4-968c-7016dfa837dc"
          encType="multipart/form-data"
        > */}
        <div className="flex-jar">
          <img
            src={MillsLogo}
            alt="Mills Publishing Logo"
            className="mills-logo"
          />
        </div>
        {/* <h2>Mills Publishing Inc.</h2>
        <h3>Contact Us</h3> */}

        {/* <div className="field half first">
            <label htmlFor="name">Name</label>
            <input type="text" name="name" id="name" required />
          </div>
          <div className="field half">
            <label htmlFor="email">Email</label>
            <input type="text" name="email" id="email" required />
          </div>
          <div className="field">
            <label htmlFor="message">Message</label>
            <textarea name="message" id="message" rows="6"></textarea>
          </div>
          Select interest COMMENT THIS OUT
          <div className="field">
            <label htmlFor="interest">Select Interest</label>
            <input type="checkbox" id="artsPrograms" name="artsPrograms" />
            <label htmlFor="artsPrograms">Arts Programs</label>
            <input
              type="checkbox"
              id="universityPrograms"
              name="universityPrograms"
            />
            <label htmlFor="universityPrograms">
              University Sports Programs
            </label>
            <input type="checkbox" id="tradeJournals" name="tradeJournals" />
            <label htmlFor="tradeJournals">Trade Journals</label>
            <input
              type="checkbox"
              id="outdoorSportsGuide"
              name="outdoorSportsGuide"
            />
            <label htmlFor="outdoorSportsGuide">Outdoor Sports Guide</label>
          </div>
          File upload COMMENT THIS OUT
          <div className="actions">
            <label htmlFor="upload">
              <i className="icon fa-file-o"></i> Upload a File
            </label>
            <input type="file" name="file" />
          </div>
          <input
            type="hidden"
            id="captchaResponse"
            name="g-recaptcha-response"
          />
          <ul className="actions">
            <li>
              <input type="submit" value="Send Message" className="special" />
            </li>
            <li>
              <input type="reset" value="Clear" />
            </li>
          </ul> */}
        {/* </form> */}
        {/* <small>
          This site is protected by reCAPTCHA and the Google{' '}
          <a href="https://policies.google.com/privacy">Privacy Policy</a> and
          <a href="https://policies.google.com/terms">Terms of Service</a>{' '}
          apply.
        </small> */}
      </section>
      <section className="split">
        <section>
          <div className="contact-method">
            <span className="icon alt fa-envelope"></span>
            <h3>Email</h3>
            <a
              className="button special"
              href="mailto:info@millspub.com?cc=patrick@millspub.com&cc=dmiller@millspub.com&cc=jackie@millspub.com"
            >
              CONTACT US
            </a>
          </div>
        </section>
        <section>
          <div className="contact-method">
            <span className="icon alt fa-phone"></span>
            <h3>Phone</h3>
            <span>
              <a href="tel:(801) 467-8833">(801) 467-8833</a>
            </span>
          </div>
        </section>
        <section>
          <div className="contact-method">
            <span className="icon alt fa-home"></span>
            <h3>Address</h3>
            <span>
              3300 South 772 East
              <br />
              Suite 200
              <br />
              Salt Lake City, UT 84106
            </span>
          </div>
        </section>
      </section>
    </div>
  </section>
);

export default Contact;
