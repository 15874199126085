import React from 'react';

const Footer = (props) => (
  <footer id="footer">
    <div className="inner">
      <ul className="icons">
        <li>
          <a
            href="https://www.facebook.com/MillsPublishing?ref=hl"
            className="icon alt fa-facebook"
          >
            <span className="label">Facebook</span>
          </a>
        </li>
      </ul>
      <ul className="copyright">
        <li>{new Date().getFullYear()} &copy; Mills Publishing Inc.</li>
        <li>
          Design: <a href="https://html5up.net">HTML5 UP</a> and Mills
          Publishing.
        </li>
      </ul>
    </div>
  </footer>
);

export default Footer;
