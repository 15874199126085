import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const Menu = (props) => (
  <nav id="menu">
    <div className="inner">
      <ul className="links">
        <li>
          <Link onClick={props.onToggleMenu} to="/">
            Home
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/digital-publications">
            View our Work
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/upcoming-publications">
            Upcoming Publications
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/arts-programs">
            Arts Programs
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/university-sports-programs">
            University Sports Programs
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/professional-journals">
            Professional Journals
          </Link>
        </li>
        <li>
          <Link
            onClick={props.onToggleMenu}
            to="/publications/outdoor-sports-guide"
          >
            Outdoor Sports Guide
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/advertising-specifications">
            Advertising Specifications
          </Link>
        </li>

        <li>
          <Link onClick={props.onToggleMenu} to="/about-us">
            About Us
          </Link>
        </li>
      </ul>
    </div>
    <a className="close" onClick={props.onToggleMenu} href="# ">
      Close
    </a>
  </nav>
);

Menu.propTypes = {
  onToggleMenu: PropTypes.func,
};

export default Menu;
